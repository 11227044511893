import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SectionItem from './section-item';
import SectionTitle from './section-title';
import { ISurveySection } from 'src/model/survey/section';

import { survey_type as survey_types } from 'src/services/config';
import { useTranslation } from 'react-i18next';
import { ISurveyQuestion } from 'src/model/survey/question';

const SectionBox = () => {
  const { t } = useTranslation();
  const { survey, survey_type, selected_section_id } = useSelector(
    (state: any) => state.survey,
  );

  useEffect(() => {}, [selected_section_id]);

  const getBgColor = () => {
    return survey_type == survey_types.survey_type_registered
      ? 'bg-lr-blue-1'
      : 'bg-lr-green-1';
  };
  
  const sectionCompleted = (section: ISurveySection) => {
    let _completed = true;
    section.questions.forEach((question: ISurveyQuestion) => {
      if (!question.selected_answer) {
        _completed = false;
        return _completed;
      }
    });
    return _completed;
  };

  return (
    <div>
      {survey && (
        <ul
          className={`menu ${getBgColor()} w-72 md:w-80 rounded-lg font-mono py-2 mr-4 md:mr-8 md:py-4`}>
          <SectionTitle title={t('assessment_category')} />
          {survey.sections.map((item: ISurveySection) => {
            return (
              <SectionItem
                key={`section-item-${item.id}`}
                section_id={item.id}
                icon={item.icon}
                title={item.name}
                active={selected_section_id == item.id}
                completed={sectionCompleted(item)}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SectionBox;
