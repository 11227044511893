import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ConfirmDialog from 'src/components/dialog/confirm-dialog';
import { ISurveySection } from 'src/model/survey/section';
import { updateSelectedSectionId } from 'src/services/util';
import { ISurveyQuestion } from 'src/model/survey/question';
import { survey_type as survey_types } from 'src/services/config';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { updateResponse } from 'src/store/survey';
import Loader from 'src/components/basic/loader';

const QuestionFooter = () => {
  const submit_modal_id = 'survey_submit_modal';
  const return_modal_id = 'survey_return_modal';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const {
    survey,
    survey_type,
    selected_section_id,
    response,
    is_loading,
    is_response_updated,
  } = useSelector((state: any) => state.survey);

  const {is_logged_in} = useSelector((state: any) => state.user);
  const [submitted, setSubmitted] = useState(false);
  const [dlgSubmitOpen, setDlgSubmitOpen] = useState(false);
  const [dlgReturnOpen, setDlgResturnOpen] = useState(false);

  useEffect(() => {
    setDlgResturnOpen(false);
  }, []);

  useEffect(() => {
    if (is_response_updated) {
      setSubmitted(true);
      setDlgSubmitOpen(true);
    }
  }, [is_response_updated]);

  const allAnswered = useMemo(() => {
    let _enabled = true;
    survey.sections.forEach((section: ISurveySection) => {
      section.questions.forEach((question: ISurveyQuestion) => {
        if (!question.selected_answer) {
          _enabled = false;
          return _enabled;
        }
      });
    });
    return _enabled;
  }, [survey]);

  const isFirstSection = () => {
    const sectionIndex = survey.sections.findIndex(
      (item: ISurveySection) => item.id === selected_section_id,
    );
    return sectionIndex === 0;
  };

  const isLastSection = () => {
    const sectionIndex = survey.sections.findIndex(
      (item: ISurveySection) => item.id === selected_section_id,
    );
    return sectionIndex === survey.sections.length - 1;
  };

  const onNextCategory = () => {
    const sectionIndex = survey.sections.findIndex(
      (item: ISurveySection) => item.id === selected_section_id,
    );
    if (sectionIndex === -1) {
      return;
    }
    if (!isLastSection()) {
      // Update Selected Category
      updateSelectedSectionId(survey.sections[sectionIndex + 1].id);
    }
  };
  const onSubmit = () => {
    const sectionIndex = survey.sections.findIndex(
      (item: ISurveySection) => item.id === selected_section_id,
    );

    if (sectionIndex === -1) {
      return;
    }
    
    setDlgSubmitOpen(true);
    if(is_logged_in){
      // Submit
      dispatch(
        updateResponse({
          response_id: response?.id ?? undefined,
        }),
      );
    };
};

  const goHome = () => {
    //Navigate home
    navigate('/home');
  };

  const goSurveyWelcome = () => {
    navigate('/survey-welcome');
  };

  const onCancel = () => {
    if (survey_type == 'survey_type_public') {
      navigate('/home');
    } else {
      setDlgResturnOpen(true);
    }
  };

  const onBackCategory = () => {
    const categoryIndex = survey.sections.findIndex(
      (item: ISurveySection) => item.id === selected_section_id,
    );

    if (categoryIndex !== -1 && categoryIndex > 0) {
      // Update Selected Category
      updateSelectedSectionId(survey.sections[categoryIndex - 1].id);
    }
  };

  const onSubmitDlgClose = () => {
    if (survey_type == survey_types.survey_type_public) {
      goHome();
    } else if (survey_type == survey_types.survey_type_registered) {
      setDlgSubmitOpen(false);
    }
  };

  const onReturnDlgClose = () => {
    setDlgResturnOpen(false);
    if (survey_type == survey_types.survey_type_public) {
      goHome();
    } else if (survey_type == survey_types.survey_type_registered) {
      goSurveyWelcome();
    }
  };

  const formatDataInRFC4180Format = (data: any) => {
    const formattedData = [];
    for (const row of data) {
      const fields = [];
      for (const field of row) {
        let innerValue = field === null ? '' : field.toString();
        if (field instanceof Date) {
          innerValue = field.toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        fields.push(result);
      }
      // Join the fields together with commas.
      formattedData.push(fields.join(','));
    }

    return formattedData;
  };

  const onDownload = () => {
    const data: any[] = [['Question Number', 'Question', 'Answer']];

    survey.sections.map((section: ISurveySection) => {
      section.questions.map((question: ISurveyQuestion) => {
        const answer = question.selected_answer;
        const _rec = [question.question_number, question.text, answer];
        data.push(_rec);
      });
    });

    //const csvData = data.map((row) => row.join(',')).join('\n');
    const csvData = formatDataInRFC4180Format(data);
    // Create a Blob containing the CSV data
    const BOM = '\ufeff';
    const blob = new Blob([BOM, csvData.join('\n')], {
      type: 'text/csv;charset=utf-8;',
    });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="flex flex-row my-4 py-2 text-gray-900 font-bold">
      {is_loading && <Loader />}
      <div className="basis-1/2"></div>
      <div className="basis-1/3 grid grid-cols-4 gap-4 ml-6">
        {!submitted && (
          <>
            <button className="btn btn-error text-white" onClick={onCancel}>
              {t('cancel')}
            </button>
            {!isFirstSection() && (
              <>
                <button
                  className="btn btn-warning text-white"
                  onClick={onBackCategory}>
                  {t('back')}
                </button>
              </>
            )}

            {!isLastSection() && (
              <button
                className="btn btn-accent text-white"
                onClick={onNextCategory}>
                {t('next')}
              </button>
            )}

            <button
              className="btn btn-primary text-white"
              onClick={onSubmit}
              disabled={
                !allAnswered && survey.configuration['allQuestionsRequired']
              }>
              {survey_type == survey_types.survey_type_registered && ( t('submit') )}
              {survey_type == survey_types.survey_type_public && ( t('exit') )}
            </button>
          </>
        )}
        {submitted && (
          <>
            <button className="btn btn-primary text-white" onClick={goHome}>
              {t('home')}
            </button>
            {survey_type == survey_types.survey_type_registered && (
              <button
                className="btn btn-accent text-white"
                onClick={onDownload}>
                {t('download')}
              </button>
            )}
          </>
        )}
      </div>
      <div className="basis-1/6 w-16 text-center"></div>

      <ConfirmDialog
        modal_id={submit_modal_id}
        is_open={dlgSubmitOpen}
        title={t('')}
        close_text={t('public_survey.close')}
        onClose={onSubmitDlgClose}>
        {survey_type == survey_types.survey_type_public && (
          <div className="mt-4">{t('public_survey.confirm_text_1')}</div>
        )}
        {survey_type == survey_types.survey_type_registered && (
          <div className="mt-4">{t('public_survey.confirm_text_2')}</div>
        )}
      </ConfirmDialog>

      <ConfirmDialog
        modal_id={return_modal_id}
        is_open={dlgReturnOpen}
        title={t('')}
        close_text={t('return_dlg.return')}
        onClose={onReturnDlgClose}>
        {t('return_dlg.text')}
      </ConfirmDialog>
    </div>
  );
};

export default QuestionFooter;
