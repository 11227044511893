import React from 'react';
import Image from 'src/components/basic/image';

import { updateSelectedSectionId } from 'src/services/util';

interface SectionItemProps {
  section_id: number;
  title: string;
  icon: string | null;
  active: boolean;
  completed: boolean;
}

const SectionItem = (props: SectionItemProps) => {
  const { section_id, title, icon, active, completed } = props;

  const onItemClicked = () => {
    updateSelectedSectionId(section_id);
  };

  const sectionCompleted = () => {
    return completed ? '' : 'hidden';
  };

  return (
    <li onClick={onItemClicked}>
      <a
        className={`text-white ${
          active ? 'bg-secondary' : ''
        } hover:bg-lr-green-2 hover:text-white`}>
        <Image
          className="w-7 mr-2 rounded-full"
          src={`assets/icons/${icon ?? 'no.png'}`}
        />
        <span className="line-clamp-4 font-calibri">{title}</span>
        <span className={`${sectionCompleted()}`}>&#10003;</span>
      </a>
    </li>
  );
};

export default SectionItem;
