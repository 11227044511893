import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISurveySection } from 'src/model/survey/section';
import * as Survey from 'src/services/survey';

import { ISurveyQuestion } from 'src/model/survey/question';
import { ISurvey } from 'src/model/survey/survey';
import { IResponse } from 'src/model/survey/response';
import { ISurveyAnswer } from 'src/model/survey/answer';

interface TSurveyData {
  selected_section_id: number | undefined;
  survey: ISurvey | undefined;
  survey_type: string | undefined;
  response_list: IResponse[];
  response: IResponse | undefined;
  is_response_get: boolean;
  is_response_created: boolean;
  is_response_updated: boolean;
  is_response_canceled: boolean;
  is_loading: boolean;
  is_success: boolean;
}

const _survey: ISurvey | undefined = undefined;
const _selected_section_id: number | undefined = undefined;
const _response_list: IResponse[] = [];
const _response: IResponse | undefined = undefined;

const initialState: TSurveyData = {
  selected_section_id: _selected_section_id,
  survey: _survey,
  survey_type: undefined,
  response_list: _response_list,
  response: _response,
  is_response_get: false,
  is_response_created: false,
  is_response_updated: false,
  is_response_canceled: false,
  is_loading: false,
  is_success: false,
};

export const getSurvey = createAsyncThunk(
  'survey/survey',
  async (payload: any) => {
    const { survey_id, lang, depth } = payload;
    return await Survey.getSurvey(survey_id, lang, depth);
  },
);

export const getResponses = createAsyncThunk(
  'survey/get_responses',
  async (payload: any) => {
    const { survey_id, user_id, status } = payload;
    return await Survey.getResponses(survey_id, user_id, status);
  },
);

export const createResponse = createAsyncThunk(
  'survey/create_response',
  async (payload: any) => {
    const { survey_id, user_id } = payload;
    return await Survey.createResponse(survey_id, user_id);
  },
);

export const updateResponse = createAsyncThunk(
  'survey/update_response',
  async (payload: any) => {
    const { response_id } = payload;
    return await Survey.updateResponse(response_id, 'submit');
  },
);

export const cancelResponse = createAsyncThunk(
  'survey/cancel_response',
  async (payload: any) => {
    const { response_id } = payload;
    return await Survey.updateResponse(response_id, 'cancel');
  },
);

export const updateAnswer = createAsyncThunk(
  'survey/update_answer',
  async (payload: any) => {
    const { response_id, question_id, answer_text } = payload;
    return await Survey.updateAnswer(response_id, question_id, answer_text);
  },
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,

  reducers: {
    updateSelectedSectionId(state, action) {
      state.selected_section_id = action.payload;
    },

    setSurveyType(state, action) {
      state.survey_type = action.payload;
    },

    setAnswer(state, action) {
      const { section_id, question_id, answer } = action.payload;
      const __survey = state.survey;
      const sections: ISurveySection[] = __survey?.sections
        ? __survey?.sections
        : [];
      const section_index = sections?.findIndex(
        (item: ISurveySection) => item.id === section_id,
      );

      if (section_index !== -1) {
        const selected_section = sections[section_index];
        const questions = selected_section.questions;
        if (questions) {
          const question_index = questions.findIndex(
            (item: ISurveyQuestion) => item.id === question_id,
          );
          if (question_index !== -1) {
            questions[question_index].selected_answer = answer;

            state.survey = {
              ...__survey,
              id: __survey?.id ?? '',
              name: __survey?.name ?? '',
              description: __survey?.description ?? '',
              status: __survey?.status ?? '',
              responses: __survey?.responses ?? [],
              sections: [
                ...sections.slice(0, section_index),
                selected_section,
                ...sections.slice(section_index + 1),
              ],
            };
          }
        }
      }
    },

    clearAnswers(state, action) {
      const section_id = action.payload;
      const __survey = state.survey;
      const sections: ISurveySection[] = __survey?.sections
        ? __survey?.sections
        : [];
      const section_index = sections?.findIndex(
        (item: ISurveySection) => item.id === section_id,
      );

      if (section_index !== -1) {
        const selected_section = sections[section_index];
        const questions = selected_section.questions;
        if (questions) {
          questions.map((question: ISurveyQuestion) => {
            delete question.selected_answer;
          });
          state.survey = {
            ...__survey,
            id: __survey?.id ?? '',
            name: __survey?.id ?? '',
            description: __survey?.id ?? '',
            status: __survey?.status ?? '',
            responses: __survey?.responses ?? [],
            sections: [
              ...sections.slice(0, section_index),
              selected_section,
              ...sections.slice(section_index + 1),
            ],
          };
        }
      }
    },
  },

  extraReducers: {
    [getSurvey.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
    },
    [getSurvey.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
    },
    [getSurvey.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      state.is_success = true;

      const _survey = action.payload;
      const _sections = _survey.sections.map((section: ISurveySection) => ({
        ...section,
        questions: section.questions.map((question: ISurveyQuestion) => {
          const answers = question.answers;
          let answer_text = undefined;
          if (answers && answers.length > 0) {
            answers.map((_answer: ISurveyAnswer) => {
              if (_answer.response_id == state.response?.id) {
                answer_text = _answer.answer_text;
              }
            });
          }

          return {
            ...question,
            selected_answer: answer_text,
          };
        }),
      }));

      state.survey = { ..._survey, sections: _sections };
    },

    [getResponses.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
      state.is_response_get = false;
      state.response_list = [];
      state.response = undefined;
    },
    [getResponses.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
      state.is_response_get = false;
      state.response_list = [];
      state.response = undefined;
    },
    [getResponses.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      state.is_success = true;
      state.is_response_get = true;
      const payload = action.payload;
      state.response_list = payload;
      if (payload && payload.length > 0) {
        state.response = payload[0];
      }
    },

    [createResponse.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
      state.is_response_created = false;
    },
    [createResponse.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
      state.is_response_created = false;
    },
    [createResponse.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      state.is_success = true;
      const payload = action.payload;
      state.response = payload;
      state.is_response_created = true;
      state.response_list = [payload];
    },

    [updateResponse.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
      state.is_response_created = false;
      state.is_response_updated = false;
    },
    [updateResponse.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
      state.is_response_created = false;
      state.is_response_updated = false;
    },
    [updateResponse.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
      state.is_response_created = false;
      state.is_response_updated = true;
    },

    [cancelResponse.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
      state.is_response_created = false;
      state.is_response_canceled = false;
    },
    [cancelResponse.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
      state.is_response_created = false;
      state.is_response_canceled = false;
    },
    [cancelResponse.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
      state.is_response_created = false;
      state.is_response_canceled = true;
    },

    [updateAnswer.pending.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
    },
    [updateAnswer.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
    },
    [updateAnswer.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
    },
  },
});

export const {
  updateSelectedSectionId,
  setSurveyType,
  setAnswer,
  clearAnswers,
} = surveySlice.actions;

export default surveySlice.reducer;
